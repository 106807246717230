.activity_card {
  width: 180px;
  height: 192px;
  border-radius: 8px;
  padding: 16px 24px 24px 24px;
  background-color: #ffffff;
  border: 0.5px solid #cfd9e2;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px 1px #00336612;
}

.activity_icon {
  position: relative;
  width: 80px;
  height: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.activity_icon_image,
.activity_icon_image_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.activity_icon_image {
  opacity: 1;
}

.activity_icon_image_hover {
  opacity: 0;
}

.activity_card:hover .activity_icon_image {
  opacity: 0;
}

.activity_card:hover .activity_icon_image_hover {
  opacity: 1;
}
.activity_name {
  text-align: start;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.0025em;
  text-align: center;
  margin-top: 12px;
  color: #003366;
}

.activity_card:hover {
  cursor: pointer;
  background: linear-gradient(180deg, #003366 0%, #125190 50%, #2877c5 100%);
  .activity_name {
    text-decoration: underline;
    color: white;
  }
}

@media screen and (max-width: 991px) {
  .activity_card {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: auto;
    width: 140px;
    height: 164px;
    padding: 8px 16px 16px 16px;
    border-radius: 8px;
    border: 0.5px;
  }

  .activity_name {
    font-size: 16px;
    padding-left: 12px;
    line-height: 26px;
  }
}


@media screen and (max-width: 1160px) {
  .activity_card {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: auto;
    width: 140px;
    height: 164px;
    padding: 8px 16px 16px 16px;
    border-radius: 8px;
    border: 0.5px;
  }
}