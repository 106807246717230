@use "fonts";
.container {
  padding: 0 2rem;
}

.nav_container {
  position: relative;
  width: 100vw;
  top: -50px;
  height: 100%;
  z-index: 1;
}

.use_case_img_container {
  overflow: hidden;
  border-radius: 8px;
}

.learnmore_text_cont {
  margin-top: 16px;
}

.article_desc {
  width: 75%;
  margin: 14px 0 35px 15px;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.25%;
  // color: #003366 ;
  color: var(--font-2);
  transition: all 0.3 ease;
}

.desktop_btn_learn_more {
  position: relative;
  right: 0;
}

.flexColCenter {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.main_layer_arrow_container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.scnd_layer_arrow_container {
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.btn_demo_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main_container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none !important;
}

.main {
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background_wrapper {
  width: 100%;
  overflow-y: hidden;
  margin-top: 65px;
}

.hero_background {
  height: 563px;
  background-position: center !important;
  max-width: 100vw;
  margin: auto;
  background-repeat: no-repeat !important;
  background-size: auto 560px !important;
}

.hero_banner_mobile {
  width: 100vw;
  height: auto;
  object-fit: contain;
}

.hero_banner_text_mobile {
  position: absolute !important;
  width: 100vw !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // top: 200px;
}

.hero_background_1 {
  background: url("../public/assets/home_hero_1.png");
  background-size: cover;
  object-position: center;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 598px;
}

.hero_background_2 {
  background: url("../public/assets/hero_banner_2.png");
  background-size: cover;
  object-position: center;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 598px;
}

.hero_background_3 {
  background: url("../public/assets/hero_banner_3.png");
  background-size: cover;
  object-position: center;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 598px;
}

.hero_desc {
  color: white;
  text-align: center;
  margin: 0px 0px 20px 0px;
}

/* .subheader { */
.hero_text {
  color: #ffffff;
  text-align: center;
  padding: 0px 16px;
}

.hero_title {
  font-size: 35px;
  line-height: 46px;
  font-weight: 800;
  letter-spacing: 0.5%;
}

.hero_subtitle {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.25%;
}

.hero_text h3:nth-child(1) {
  margin-top: 8px;
}

.hero_text p:nth-child(2) {
  display: none;
}

.hero_menu {
  flex-grow: 2;
  margin-top: 40px;
  padding: 0px 4px;
  width: 100%;
  column-gap: 20px;
  row-gap: 8px;
  max-width: 180px;
  text-align: center;
}

.hero_menu > * {
  text-align: center;
}

.hero_button_demo_container {
  display: flex;
  justify-content: center;
  z-index: 10;
}

.bullets {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  bottom: 32px;
  z-index: 0;
}

.bullets_mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  top: -48px;
  z-index: 0;
}

.hero_bullets {
  position: relative;
  bottom: calc(100vw / 7);
  right: calc(100vw / 7);
  display: flex;
  justify-content: center;
  margin-top: 30px;
  z-index: 100;
}

.bullet {
  height: 8px;
  width: 8px;
  background-color: #e3e3e3;
  border-radius: 50%;
  display: inline-block;
  margin: 7px;
  cursor: pointer;
}

.bullet.active {
  background-color: var(--secondary);
}

.hero_back {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 510px;
  right: 80vw;
  cursor: pointer;
  z-index: 1;
}

.hero_next_mobile {
  position: absolute;
  height: 24px;
  width: 24px;
  cursor: pointer;
  right: 100px;
}

.hero_next {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 80vw;
  top: 510px;
  cursor: pointer;
  z-index: 1;
}

.hero_back_mobile {
  position: absolute;
  height: 24px;
  width: 24px;
  cursor: pointer;
  left: 100px;
}

.clients {
  padding-top: 42px;
}

.clients div:first-child {
  padding: 0 24px 0 24px;
}

.clients_logo {
  padding: 30px auto 10px auto;
  justify-content: center;
  align-items: center;
  gap: 32px 24px;
  overflow-x: scroll;
  scrollbar-width: none;
  flex-wrap: wrap;
}

.clients_logo::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.clients_img_container {
  width: 160px;
}

.clients_logo img {
  height: fit-content;
  height: 40px;
  max-height: 71px;
  max-width: 120px;
  overflow-anchor: none !important;
}

.clients_logo > img:first-child {
  max-width: 100px;
}

.proposition {
  padding: 12.5px 0 12.5px 0;
}

.proposition > div:first-child {
  padding: 0 79px 0px 69px;
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #1f1212;
}

.proposition_list {
  justify-content: center;
  flex-direction: column;
  margin: 4px 16px 0 17px;
}

.proposition_list > div:last-child hr {
  display: none;
}

.proposition_box {
  border-radius: 0px;
  padding: 16px 0;
  justify-content: left;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-top: 0;
  img:first-child {
    margin: 4px 18px 0px 0;
    grid-column: 1 / 2;
    width: 50px;
    height: 50px;
    font-weight: 600;
    text-align: left;
  }
}

.proposition_bottom_border {
  text-align: left;
  justify-items: left;
  border-radius: 0px;
  width: 90%;
  border: none;
  height: 1px;
  border-color: #d1d1d1;
  background-color: #d1d1d1;
}

.proposition_box img {
  margin: 4px 18px 0px 0;
  grid-column: 1 / 2;
}

.proposition_box div:nth-child(2) {
  margin-bottom: 8px;
  grid-column: 2 / 9;
  color: var(--primary);
}

.proposition_box div:nth-child(3) {
  color: #7c7c7c;
  text-align: left;
}

.proposition_box div:nth-child(3) > span {
  font-weight: 600;
  color: var(--primary);
}

.how_we_can_help {
  background-color: #f3f8ff;
}

.use_case {
  padding: 40px 0px 46px 0;
}

.use_case > div:first-child {
  align-items: center;
  margin: 0 21.94px 0 17px;
  color: var(--font-1);
  > p {
    // background-color: red;
    // @include fonts.mhu_header_3;
    // font-weight: 600;
    font-family: "Nunito Sans";
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #003366 ;
    transition: all 0.3 ease;
  }
}

.use_case > div:first-child > p {
  flex-grow: 2;
}

.use_case > div:nth-child(2) {
  margin: 8px 17px;
  color: #333333;
}


.use_case_more_example {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;

  p {
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: 600;
  }

  img {
    margin-left: 16px;
    width: 12px;
    height: 20px;
  }
}

.use_case_desc {
  // background-color: red;
  margin: 8px 17px;
  color: #333333;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.25%;
}


@media screen and (max-width: 992px) {
  .use_case_more_example {
    p {
      display: none;
    }
  }
  .use_case > div:first-child {
    // width: 100%;
    > p {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
    }

  }
  .use_case_desc {
    // background-color: red;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
  .article_box > div:nth-child(2) {
    margin-right: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .article_box > div:nth-child(3) {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px;
    margin-right: 12px;
    height: 40%;
  }

  .articles_title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }

  .article_desc {
    width: 75%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.4%;
  }

}

.use_case_box {
  margin: 8px auto 0 auto;
  height: auto;
  width: 280px;
  border-radius: 0px;
  padding: 12px;
  box-shadow: 0px 4px 16px 0px #0000001a;
}

.use_case_box > img {
  width: 100%;
  height: 171px;
}

.use_case_ctn > div:first-child {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 8px 0;
}

.use_case_ctn > div:nth-child(2) {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.use_case_ctn > div:nth-child(2) > div > span {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.articles,
.testimonials {
  padding: 40px 0px 46px 0;
}

.articles > div:first-child,
.testimonials > div:first-child {
  align-items: center;
  margin: 0 21.94px 0 17px;
  color: var(--font-1);
}

.articles > div:first-child > div {
  flex-grow: 2;
}

.articles_title {
  font-family: "Nunito Sans";
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0.5%;
  color: #003366 ;
  transition: all 0.3 ease;
}

.articles_show_more {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: #003366;
  margin-left: 45px;
  flex-grow: 1;
  text-align: right;
}

.articles_show_more > span {
  display: none;
}

.articles > div:nth-child(2),
.testimonials > div:nth-child(2) {
  margin: 8px 17px;
  color: var(--font-2);
}

.article_list {
  border-radius: 0px;
  width: 100%;
  overflow-x: auto;
  gap: 12px;
  padding-right: 27px;
  padding-left: 27px;
  scrollbar-color: unset;
  scrollbar-width: none;
}

.article_list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.article_list::-webkit-scrollbar-track-piece {
  background-color: transparent;
  display: none;
}

.article_list::-webkit-scrollbar-thumb:vertical {
  background-color: transparent;
  display: none;
}

.article_list > a {
  text-decoration: none;
}

.article_box {
  box-shadow: 0px 4px 16px 0px #0000001a;
  width: 280px;
  padding: 12px;
  margin: 2px 0 14px 9px;
  display: flex;
  flex-direction: column;
}

.articles_show_more {
  margin-top: auto;
  margin-bottom: auto;
}

.articles_show_more img {
  width: 10px;
  height: 16.67px;
}

.article_box > div:nth-child(2) {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15%;
  text-align: left;
  margin: 8px 0 8px 0;
  color: #000000;
}

.article_box > div:nth-child(3) {
  font-family: "Inter";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.13%;
  color: var(--font-2);
  flex-grow: 1;
  height: 40%;
}

.article_box > a:nth-child(4) div {
  text-align: right;
  text-decoration-line: underline;
  margin: 4px 5px 0 0;
}

.proposition_box div:nth-child(3) {
  grid-column: 2 / 9;
}

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.request_form_modal {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 375px;
  height: 714px;
}

.benefit_highlight {
  text-align: center;
  margin-top: 27.5px;
  padding: 0 34px 40px 27px;
}

.benefit_highlight > p:first-child {
  font-family: "Nunito Sans";
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0.5%;
  color: #003366 ;
  width: 192px;
  margin: 0 auto;
  margin-bottom: 8px;
  transition: all 0.3 ease;
}

.benefit_highlight > div:nth-child(2) {
  font-family: "Nunito Sans";
  margin: auto;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 283px;
  color: #1f1212;
}

.tab_menu {
  display: none;
}

.tab_menu_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* flex-direction: row;
justify-content: space-around; */
  gap: 160px;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #7c7c7c;
}

.tab_menu_list > div {
  padding-bottom: 26px;
  /* min-height: 75px; */
  cursor: pointer;
}

.tab_menu_list > div.active {
  border-top: none;
  border-bottom: 4px solid #39a3df;
  color: #39a3df;
  font-weight: 600;
}

.benefit_content > img {
  width: 301px;
  height: 172px;
  /* box-shadow: 0px 4px 28px 0px #00336626; */
}

.benefit_content > img.website_ver {
  display: none;
}

.benefit_content > .bullets {
  border-top: 1px solid #d1d1d1;
  margin-bottom: 0;
  padding-top: 8px;
}

.benefit_content > .bullets > .bullet {
  margin-top: 0;
  margin-bottom: 0;
}

.benefit_content > .bullets > .bullet.active {
  background-color: #c4c4c4;
}

.benefit_back {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 10px;
}

.benefit_next {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
}

.benefit_content > div:nth-child(4) > p {
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.15%;
  text-align: center;
  color: var(--primary);
  margin-top: 14px;
}

.benefit_content > p {
  font-family: "Inter";
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  /* Typography/Grey */
  color: #7c7c7c;
}

.request_section_btn {
  margin: 16px auto 16px auto;
  font-size: 18px;
  width: 171px;
  height: 40px;
}

.media_publication_section {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 60px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;

  .media_publication_title {
    margin-bottom: 8px;
    font-family: "Nunito Sans";
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 0.5%;
    color: #003366 ;
    transition: all 0.3 ease;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .media_publication_list {
    display: flex;
  }

  .media_publication_image {
    padding: 16px 17px;
  }
}

.solution_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 60px 0;
  padding: 60px 0px;
}

.solution_section_title {
  font-size: 24px;
  font-style: normal;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0em;
  color: #1f1212;
}

.solution_section_desc {
  margin-top: 7.7px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.0025em;
  text-align: center;
  color: #2b4156;
  margin-top: 24px;
  padding: 0px 16px;
}

.activity_card_container {
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  max-width: 75%;
  justify-content: center;
  padding-top: 24px;
  margin: 0 auto;
}

.testimonial_detail_right_text p span{
  color: #607A93 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  font-family: "Inter";
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* Safari Only CSS here */
    .request_section_btn {
      width: 174px !important;
    }
    .use_case_ctn > div:nth-child(2) {
      font-size: 13px;
    }
    .hero_menu {
      max-width: 160px;
    }
  }
}

/** very small phones (galaxy fold) */

@media screen and (max-width: 285px) {
  .proposition > div:first-child {
    font-size: 1.67em;
  }
  .nav_container {
    display: none;
  }
  .bullets_mobile {
    display: none;
  }
}

@media screen and (max-width: 322px) {
  .benefit_content > img {
    width: 270px;
  }
  .clients_logo {
    gap: 12px;
  }
  .clients_logo img {
    margin-top: 20px;
    max-height: 60px;
    max-width: 90px;
  }
  .clients_logo > img:first-child {
    max-width: 80px;
  }
  .hero_next_mobile {
    right: 50px;
  }
  .hero_back_mobile {
    left: 50px;
  }
}

@media screen and (max-width: 370px) {
  .clients_logo {
    justify-content: left;
  }
}

/** small phones styles */

@media screen and (max-width: 395px) {
  .proposition > div:first-child {
    padding: 0 65px 0 55px;
    font-size: 1.67em;
  }
}
/** mini tab styles */

@media screen and (max-width: 768px) {
  .clients_logo {
    display: flex;
    flex-direction: row;
    padding: 24px 0 0 0 !important;
  }
  .clients_logo img {
    padding: 0 !important;
  }
  .media_publication_section {
    padding-bottom: 40px;
    padding-top: 16px;

    .media_publication_title {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
    }

    .media_publication_katadata_image,
    .media_publication_bisniscom_image {
      padding: 16px 8px !important;
    }
  }

  .media_publication_section {
    .media_publication_image {
      padding: 16px 12px;
    }
  }

  .media_publication_list_container {
    width: 639px;

    .bisniscom_img {
      width: 120px;
      height: 26px;
    }

    .katadata_img {
      width: 178.48px;
      height: 26px;
    }

    .mediaindonesia_img {
      width: 74.3px;
      height: 26px;
    }

    .investorid_img {
      width: 139.15px;
      height: 26px;
    }

    .swacoid_img {
      width: 58.5px;
      height: 26px;
    }

    .marketingcoid_img {
      width: 143.8px;
      height: 26px;
    }

    .liputan6_img {
      width: 130px;
      height: 26px;
    }
  }
  .activity_card_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Membuat 2 kolom */
    gap: 16px; /* Jarak antar card */
    padding: 16px;
  }

  /* Styling untuk card kelima yang sejajar dengan card 3 */
  .activity_card_container > a:nth-child(5) {
    grid-column: 2; /* Menempatkan di kolom kedua */
    grid-row: 2; /* Menempatkan sejajar dengan card 3 */
  }
  .solution_section_title {
    font-size: 18px;
    line-height: 26px;
  }
  .solution_section_desc {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (min-width: 768px) {
  .proposition_box img {
    margin: 0 12px 0px 0;
  }
  .proposition > div:first-child {
    margin: 12px 0;
  }
  .articles > div:nth-child(2),
  .testimonials > div:nth-child(2) {
    max-width: 100%;
  }
  .clients_logo {
    gap: 48px 30px;
    padding: 24px 0 0 0 !important;
  }
  .nav_container {
    position: relative;
    width: 100vw;
    top: -100px;
    height: 100%;
    z-index: 1;
  }
}


@media screen and (max-width: 992px) { 
  .hero_text h3:nth-child(1) {
    // margin-right: 100px;
    // font-size: 40px;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
  }
}


@media screen and (max-width: 992px) {
  .benefit_highlight > p:first-child {
    font-family: "Nunito Sans";
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    width: 192px;
    margin: 0 auto;
    margin-bottom: 8px
    // color: #1f1212;
  }
  

  .activity_card_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px; 
    padding: 16px;
  }

  .activity_card_container > a:nth-child(5) {
    grid-column: 2; 
    grid-row: 2;
  }
}

/** tab styles */

@media screen and (min-width: 992px) {
  .background_wrapper {
    margin-top: 0px;
  }

  .btn_demo_container {
    justify-content: flex-start !important;
  }

  .use_case_ctn {
    align-self: center;
  }
  .hero_back {
    display: block;
    height: 48px;
    width: 48px;
    position: absolute;
    left: 2vw;
    right: unset;
    top: 16vw;
    cursor: pointer;
  }
  .hero_button_demo_container {
    display: flex;
    justify-content: flex-start;
  }
  .hero_next {
    display: block;
    height: 48px;
    width: 48px;
    position: absolute;
    top: 16vw;
    cursor: pointer;
    left: unset;
    right: 2vw;
  }
  .hero {
    display: block;
    align-items: flex-start;
  }
  .hero_desc {
    margin: 0px 20px 0px 40px;
    display: grid;
    grid-template-columns: 775px 48px 48px;
    max-height: 100%;
  }
  .hero_text {
    text-align: left;
    max-width: 675px;
  }
  .hero_text h3:nth-child(1) {
    margin-right: 100px;
    font-family: "Nunito Sans";
    font-size: 35px;
    line-height: 46px;
    font-weight: 800;
    letter-spacing: 0.5%;
    // font-size: 40px;
  }
  .hero_text p:nth-child(2) {
    display: flex;
    margin-top: 40px;
    font-size: 1.125em;
    line-height: 32px;
  }
  .hero_menu {
    grid-template-columns: repeat(16, 1fr);
  }
  .hero_menu button:first-child {
    grid-column: 1 / 4;
    max-width: 180px;
  }
  .hero_menu button:nth-child(2) {
    grid-column: 4 / 7;
    max-width: 180px;
  }
  .bullets {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-height: 80%;
    position: relative;
    bottom: 30px;
    right: 100px;
  }
  .bullet {
    margin: 0 16px 0 16px;
    height: 12px;
    width: 12px;
  }
  .proposition {
    padding: 30px 0 30px 0;
  }
  .proposition > div:first-child {
    width: 100%;
    margin: 12px 0 0 0;
    font-size: 32px;
    line-height: 48px;
  }
  .proposition_list {
    flex-direction: row;
    gap: 55px;
    margin-top: 41px;
    margin-left: 0;
    margin-right: 0;
  }
  .proposition_box {
    padding: 0px;
    min-height: 438px;
    width: 252px;
    display: block;
    img:first-child {
      width: 90px;
      height: 70px;
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      /* Safari Only CSS here */
      .proposition_box {
        min-height: 438px;
      }
    }
  }
  .proposition_box img {
    margin-bottom: 16px;
    /* height: fit-content;
    width: fit-content; */
    height: 111px;
    max-height: 111px;
    /* max-width: 125px; */
  }
  .img_newspaper {
    min-height: 112px;
    min-width: 125px;
    border-radius: 0px;
  }
  .img_book {
    min-height: 110px;
    min-width: 110px;
    border-radius: 0px;
  }
  .img_laptop {
    min-height: 111px;
    min-width: 111px;
    border-radius: 0px;
  }
  .proposition_box div:nth-child(2) {
    margin-bottom: 16px;
    text-align: left;
    height: fit-content;
    min-height: fit-content;
    border-bottom: 2px solid #0052a3;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
  }
  .proposition_box div:nth-child(3) {
    text-align: left;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400;
    color: #333333;
  }
  .proposition_bottom_border {
    display: none;
    justify-content: center;
    width: 70%;
    margin-inline-start: 0;
    margin-top: 28px;
    height: 2px;
    border-color: #0052a3;
    background-color: #0052a3;
    position: relative;
    top: 0px;
    /* border: 2px solid #0052A3; */
  }
  .proposition_list > div:last-child hr {
    /* display: block; */
    display: none;
  }
  .clients {
    padding: 100px auto 0 auto;
  }
  .clients_logo {
    /* justify-content: flex-start; */
    /* justify-content: space-between; */
    justify-content: center;
    width: 100%;
    padding: 40px 0px 50px 0px;
  }
  .clients_logo img {
    height: 60px;
    max-height: 118px;
    max-width: 298px;
  }
  .clients_img_container {
    width: 240px;
  }
  .articles {
    /* padding: 27px 165px 60px 154px; */
    padding: 60px 0px 60px 0px;
  }
  .article_list {
    // text-align: center;
    // justify-content: center;
    gap: 40px;
  }
  .justify_flex_start {
    justify-content: flex-start;
  }
  .articles > div:first-child,
  .testimonials > div:first-child {
    margin-left: 15px;
  }
  .articles > div:nth-child(2),
  .testimonials > div:nth-child(2) {
    margin: 14px 0 35px 15px;
    font-size: 1.125em;
    font-weight: 400;
    max-width: 100%;
  }
  .articles_show_more {
    @include fonts.hu_header_6;
    letter-spacing: 0em;
    color: #003366;
  }
  .articles_show_more span {
    display: inline;
  }
  .articles_show_more img {
    margin: auto 0 auto 16px;
    height: 20px;
    width: 12px;
    border-radius: 0px;
    padding-top: 4px;
  }
  .article_box {
    width: 304px;
    padding: 24px;
  }
  // .article_box > div:nth-child(2) {
  //   font-size: 1.125em;
  //   line-height: 32px;
  //   margin-right: 12px;
  // }
  // .article_box > div:nth-child(3) {
  //   font-size: 1em;
  //   font-weight: 400;
  //   line-height: 24px;
  //   margin-right: 12px;
  //   height: 40%;
  // }
  .article_box > a:nth-child(4) div {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-top: 12px;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      /* Safari Only CSS here */
    }
  }
  @-moz-document url-prefix() {
    /** firefox only css */
  }
  .request_form_modal {
    width: 728px;
    height: 770px;
  }
  .benefit_highlight {
    text-align: center;
    margin-top: 30px;
    /* margin-top: 30px; */
    padding: 0 34px 60px 27px;
  }
  .benefit_highlight > div:first-child {
    // font-size: 28px;
    // line-height: 40px;
    width: 307px;
    margin-bottom: 16px;
    // color: #1f1212;
  }
  .benefit_highlight > div:nth-child(2) {
    font-family: "Nunito Sans";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: auto;
    margin-bottom: 24px;
    width: 100%;
  }
  .tab_menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 150px;
    font-size: 24px;
    line-height: 32px;
    color: #7c7c7c;
  }
  .tab_menu > div {
    padding-bottom: 26px;
    cursor: pointer;
  }
  @keyframes soft-border {
    0% {
      border-image: linear-gradient(to right, #39a3df 0%, #fff 50%) 100% 1;
    }
    25% {
      border-image: linear-gradient(to right, #39a3df 25%, #fff) 100% 1;
    }
    50% {
      border-image: linear-gradient(to right, #39a3df 50%, #fff) 100% 1;
    }
    75% {
      border-image: linear-gradient(to right, #39a3df 75%, #fff) 100% 1;
    }
    100% {
      border-image: linear-gradient(to right, #39a3df 100%, #fff 0%) 100% 1;
    }
  }
  .tab_menu > div.active {
    border-top: none;
    border-bottom: 4px solid #39a3df;
    color: #39a3df;

    // animation: soft-border .1s;
    // transition-timing-function: linear;
    font-weight: 600;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      /* Safari Only CSS here */
      .tab_menu > div {
        min-width: 270px;
      }
      .tab_menu > div.active {
        border-width: 0;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-bottom-color: #39a3df;
      }
    }
  }
  .benefit_content {
    text-align: center;
  }
  .benefit_content > img:first-child {
    display: none;
  }
  .benefit_content > img.website_ver {
    display: inline;
    width: auto;
    height: 332px;
  }
  .benefit_content > .bullets {
    display: none;
  }
  .benefit_content > div:nth-child(3) {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: var(--primary);
    margin-top: 0px;
  }
  .benefit_content > div:nth-child(4)  > p {
    font-family: 'Nunito Sans';
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    max-width: 737px;
    margin: 8px auto 0 auto;
    color: #003366;
  }
  .benefit_content > p {
    font-family: "Inter";
    text-align: center;
    max-width: 737px;
    margin: 8px auto 0 auto;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #333333;
  }
  .request_section_btn {
    margin: 24px auto 24px auto;
    font-size: 1em;
    width: 150px;
    height: 52px;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      /* Safari Only CSS here */
      .request_section_btn {
        width: 178px !important;
      }
    }
  }
  .use_case {
    padding: 71px 0px 71px 0px;
  }
  // .use_case > div:first-child {
  //   width: 100%;
  //   > div:first-child {
  //     @include fonts.hu_header_3;
  //   }
  // }
  // .use_case > div:nth-child(2) {
  //   font-size: 1.125em;
  //   font-weight: 400;
  //   margin-top: 16px;
  //   margin-bottom: 16px;
  // }
  .use_case_box {
    margin: 0 0px 0 17px;
    width: 100%;
    border-radius: 0px;
    padding: 0px 10px 0 0;
    box-shadow: 0px 0px 0px 0px transparent;
    display: flex;
    gap: 50px;
  }
  .use_case_box > img {
    height: 314px;
    width: 470px;
  }
  .use_case_ctn > div:first-child {
    @include fonts.hu_header_5;
    color: var(--primary);
    font-weight: 600;
    margin: 0 0 8px 0;
  }
  .use_case_ctn > div:nth-child(2) {
    @include fonts.hu_header_3;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    justify-items: center;
    text-align: justify;
  }
  .use_case_ctn > div:nth-child(2) > span {
    font-size: 20px;
    line-height: 32px;
    margin-left: 4px;
  }
  .use_case_btn {
    // @include fonts.hu_paragraph;
    // font-weight: 600;
    font-weight: 400;
    font-family: "Nunito Sans";
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 3%;
    color: var(--primary);
    border: 2px solid var(--primary);
    width: 246px;
    height: 52px;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    position: relative;
    left: 0px;
    margin-top: 11px;
    > img {
      width: 12px;
      height: 21px;
    }
  }
  .use_case_btn_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .use_case_title_container {
    font-family: "Nunito Sans";
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 0.15%;
    text-align: left !important;
  }

  .testimonials {
    .testimonial_detail {
      display: flex !important;
      align-items: center;
      margin-left: 15px !important;
      margin-top: 30px !important;

      .testimonial_detail_left {
        position: relative !important;
        width: 226px !important;
        min-width: 226px !important;
        margin-right: 40px !important;

        .testimonial_photo_bg {
          position: absolute !important;
          right: 0 !important;
          width: 176px !important;
          height: 144px !important;
          z-index: 1 !important;
        }

        .testimonial_photo_user {
          margin-top: 20px !important;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05) !important;
          z-index: 2 !important;
          border-radius: 100px !important;
          width: 200px !important;
          height: 200px !important;
          position: relative !important;
        }
      }

      .testimonial_detail_right_text p span{
        color: #607A93 !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 26px !important;
        font-family: "Inter";
      }

      .testimonial_detail_right_text div p {
        color: #607A93 !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 26px !important;
      }

      .testimonial_detail_right_text div p:first-child:before {
        display: block !important;
        content: open-quote !important;
        top: 0.2em !important;
      }

      .testimonial_detail_right_text div p:last-child:after {
        content: close-quote !important;
        display: inline-block !important;
        left: 6px !important;
        top: 0.3em !important;
      }

      .testimonial_detail_right_text div p:first-child:before,
      .testimonial_detail_right_text div p:last-child:after {
        vertical-align: bottom !important;
        color: #003366 !important;
        font-size: 70px !important;
        font-family: "Inter" !important;
        font-weight: 700 !important;
        position: relative !important;
      }
      .testimonial_detail_right_user {
        margin-top: 50px !important;
        font-size: 18px !important;
        color: #333333 !important;
        font-weight: 600 !important;

        span {
          font-weight: 400 !important;
        }
      }
    }

    .testimonial_pos_nav {
      margin-top: 24px !important;
      display: flex !important;
      flex-direction: row-reverse !important;

      div:first-child {
        margin-left: 24px !important;
      }

      div {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        color: #003366 !important;
        cursor: pointer !important;
      }
    }
  }
}

/** desktop styles */

@media screen and (min-width: 1200px) {
  .hero_background {
    height: calc(100vw / 2.5);
    background-position: inherit !important;
    background-repeat: repeat !important;
    background-size: 100vw !important;
    background-attachment: calc(100vw);
    max-width: 100vw;
  }
  .hero_desc {
    max-width: 900px;
  }
  .hero_text {
    max-width: 820px;
    left: 18em;
  }
  .hero_bullets {
    justify-content: end;
    position: absolute;
  }
  .bullets {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-height: 80%;
    position: relative;
    bottom: 50px;
    right: 160px;
  }
  .proposition_list {
    gap: 60px;
  }
  .proposition_box {
    width: 308px;
    min-height: 380px;
    max-height: 466px;
  }

  .title_box {
    height: 7.7em !important;
    width: 250px;

    > span {
      font-weight: 700 !important;
    }
  }

  .title_box_id {
    height: 6em !important;
    width: 250px;

    > span {
      font-weight: 700 !important;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      /* Safari Only CSS here */
      .proposition_box {
        min-height: 448px;
      }

      .hero_menu {
        max-width: 500px;
        > button {
          width: 300px !important;
          // font-size: 14px !important;
        }
      }
    }
  }
  .proposition_bottom_border {
    margin-top: 16px;
  }
}

.media_publication_list_container {
  width: 1181px;
}

.bisniscom_img {
  width: 213.5px;
  height: 48px;
}

.katadata_img {
  width: 329.5px;
  height: 48px;
}

.mediaindonesia_img {
  width: 137.14px;
  height: 48px;
}

.investorid_img {
  width: 256.89px;
  height: 48px;
}

.swacoid_img {
  width: 108px;
  height: 48px;
}

.marketingcoid_img {
  width: 265.54px;
  height: 48px;
}

.liputan6_img {
  width: 240px;
  height: 48px;
}



.testimonials_title {
  font-family: "Nunito Sans";
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: 0.5%;
  color: #003366 ;
  transition: all 0.3 ease;
}

@media screen and (max-width: 992px) { 
  .testimonials_title {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
  }
}


.testimonials {
  .testimonial_subtitle {
    margin-bottom: 0px !important;
    font-family: "Nunito Sans";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.4%;
    color: var(--font-2);
  }

  .testimonial_detail {
    margin-left: 17px;
    margin-top: 26px;

    .testimonial_detail_left {
      position: relative;
      width: 180px;
      min-width: 180px;
      margin-right: 0px;

      .testimonial_photo_bg {
        position: absolute;
        right: 0;
        width: 134px;
        height: 110px;
        z-index: 1;
      }

      .testimonial_photo_user {
        margin-top: 20px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
        z-index: 2;
        border-radius: 100px;
        width: 160px;
        height: 160px;
        position: relative;
      }
    }

    .testimonial_detail_right {
      margin-top: 16px;
      margin-right: 20px;
    }

    .testimonial_detail_right_text div p {
      color: #333333;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .testimonial_detail_right_text div p:first-child:before {
      display: block;
      content: open-quote;
      top: 0.2em;
    }

    .testimonial_detail_right_text div p:last-child:after {
      content: close-quote;
      display: inline-block;
      left: 6px;
      top: 0.3em;
    }

    .testimonial_detail_right_text div p:first-child:before,
    .testimonial_detail_right_text div p:last-child:after {
      vertical-align: bottom;
      color: #003366;
      font-size: 36px;
      font-family: "Inter";
      font-weight: 700;
      position: relative;
    }
    .testimonial_detail_right_user {
      margin-top: 24px;
      font-size: 14px;
      color: #333333;
      font-weight: 600;

      span {
        font-weight: 400;
      }
    }
  }

  .testimonial_pos_nav {
    margin-top: 24px;
    margin-right: 17px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      margin-left: 24px;
    }

    div {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: #003366;
      cursor: pointer;
    }
  }
}
