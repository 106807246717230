@mixin hu-header-1 {
    font-size: 40px;
    line-height: 58px;
}

@mixin hu-header-2 {
    font-size: 32px;
    line-height: 48px;
}

@mixin hu-header-2 {
    font-size: 32px;
    line-height: 48px;
}

@mixin hu-header-3 {
    font-size: 28px;
    line-height: 40px;
}

@mixin hu-header-4 {
    font-size: 24px;
    line-height: 32px;
}

@mixin hu-header-5 {
    font-size: 20px;
    line-height: 32px;
}

@mixin hu-header-6 {
    font-size: 18px;
    line-height: 32px;
}

@mixin hu-paragraph {
    font-size: 16px;
    line-height: 24px;
}

@mixin hu-body {
    font-size: 32px;
    line-height: 48px;
}

@mixin hu-body {
    font-size: 14px;
    line-height: 24px;
}

@mixin hu-small {
    font-size: 12px;
    line-height: 20px;
}

@mixin hu-extra-small {
    font-size: 10px;
    line-height: 16px;
}

// Mobile
@mixin mhu-header-1 {
    font-size: 24px;
    line-height: 32px;
}

@mixin mhu-header-2 {
    font-size: 22px;
    line-height: 30px;
    color: red;
}

@mixin mhu-header-3 {
    font-size: 20px;
    line-height: 28px;
}

@mixin mhu-header-4 {
    font-size: 18px;
    line-height: 24px;
}

@mixin mhu-header-5 {
    font-size: 16px;
    line-height: 24px;
}

@mixin mhu-header-6 {
    font-size: 14px;
    line-height: 24px;
}

@mixin mhu-paragraph {
    font-size: 14px;
    line-height: 24px;
}

@mixin mhu-body {
    font-size: 12px;
    line-height: 18px;
}

@mixin mhu-body {
    font-size: 10px;
    line-height: 16px;
}

@mixin mhu-small {
    font-size: 8px;
    line-height: 14px;
}

@mixin mhu-extra-small {
    font-size: 10px;
    line-height: 16px;
}